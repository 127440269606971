import { DialogMixin } from '@/mixins/dialog';
import { ResourceStatusEnum, PublicApproveStatusEnum, BusinessTypeEnum } from '@/resource/enum';
import { ServiceItemList } from '@/resource/model';
import { translation } from '@/utils';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'ServiceItemDetails'
})
export default class ServiceItemDetails extends mixins(DialogMixin) {
  @Prop({
    required: false,
    type: Object,
    default: () => {
      return null;
    }
  })
  public position!: ServiceItemList | null;
  public title: string = '服务项目';
  public form: ServiceItemList = {
    id: 0,
    name: '',
    code: '',
    type: 0,
    createTime: '',
    createUserName: '',
    forbiddenTime: '',
    forbiddenUserName: '',
    updateTime: '',
    updateUserName: '',
    approveStatus: 0,
    approveTime: '',
    approveUserName: '',
    enableStatus: 0
  };
  public disabled: boolean = true;

  public dialogOpen(): void {
    this.title = 'serviceItem.title';
    this.$nextTick(() => {
      Object.assign(this.form, this.position);
    });
    return;
  }

  public dialogClosed(): void {
    this.$emit('dialog-closed');
  }

  private get getApproveStatusI18Key(): string {
    return translation('publicApproveStatus.' + PublicApproveStatusEnum[this.form!.approveStatus]);
  }
  private get getType(): string {
    return translation(`businessType.${BusinessTypeEnum[this.form!.type!]}`);
  }
  private get getStatusI18Key(): string {
    let res;
    switch (this.form!.enableStatus) {
      case ResourceStatusEnum.using:
        res = 'common.startUsing';
        break;
      case ResourceStatusEnum.disabled:
        res = 'common.disabled';
        break;
      default:
        res = 'common.unKnownStatus';
        break;
    }
    return translation(res);
  }
}
