import { Component, Watch } from 'vue-property-decorator';
import { OsTable } from '@/components';
import { OsTableColumn, OsTableOption, RowOperation } from '@/components/os-table/os-table';
import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';
import { ServiceItemList, CreateServiceItem, ServiceItemQuery } from '@/resource/model';
import { OperationOption } from '@/components/os-table-operation/os-table-operation';
import {
  messageError,
  translation,
  getResourceStatusOptions,
  getResourceApproveStatusOptions,
  dateFormat,
  debounce
} from '@/utils';
import { Message, MessageBox } from 'element-ui';
import { BusinessTypeEnum, ResourceStatusEnum } from '@/resource/enum';
import { mixins } from 'vue-class-component';
import { PagingMixin } from '@/mixins/paging';
import { ServiceItemService } from '@/api/';
import { CustomColumnMixin } from '@/mixins/custom-column';
import { Paging, SortOptions } from '@/api/base';
import { MessageBoxData } from 'element-ui/types/message-box';
import AddServiceItem from './add-service-item/add-service-item.vue';
import ServiceItemDetails from './service-item-details/service-item-details.vue';
import { PublicApproveStatusEnum } from '@/resource/enum/approve-status';
import { ImportFile } from '@/views/dialogs';

@Component({
  name: 'SeeviceItem',
  components: { AddServiceItem, ServiceItemDetails, ImportFile }
})
export default class SeeviceItem extends mixins(PagingMixin, CustomColumnMixin) {
  public tableOption: OsTableOption<ServiceItemList> = {
    loading: false,
    data: [],
    fit: true
  };

  /**
   * 默认的表格列配置
   */
  public defaultColumnOptions: Array<OsTableColumn<ServiceItemList>> = [
    {
      type: 'selection',
      prop: 'id',
      label: '',
      reserveSelection: true,
      fixed: true
    },
    {
      prop: 'code',
      label: 'serviceItem.code',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'name',
      label: 'serviceItem.name',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'type',
      label: 'serviceItem.type',
      minWidth: '100px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        return translation(`businessType.${BusinessTypeEnum[(row as ServiceItemList).type!]}`);
      }
    },

    {
      prop: 'approveStatus',
      label: 'serviceItem.approveStatus',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'enableStatus',
      label: 'serviceItem.enableStatus',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'createUserName',
      label: 'serviceItem.createUserName',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'createTime',
      label: 'serviceItem.createTime',
      minWidth: '150px',
      showOverflowTooltip: true,
      formatter: (row: object): string => {
        return dateFormat((row as ServiceItemList).createTime, 'YYYY-MM-DD');
      }
    }
  ];

  /**
   * table上方的条件查询配置
   */
  public queryItemsOption: Array<OsQueryItemOption<ServiceItemQuery>> = [
    {
      type: 'Input',
      field: 'code',
      label: 'serviceItem.code',
      option: {
        placeholder: 'serviceItem.code'
      }
    },
    {
      type: 'Input',
      field: 'name',
      label: 'serviceItem.name',
      option: {
        placeholder: 'serviceItem.name'
      }
    },
    {
      type: 'Select',
      field: 'approveStatus',
      label: 'unitsMeasureGroups.approveStatus',
      option: {
        placeholder: 'unitsMeasureGroups.approveStatus'
      },
      optionData: getResourceApproveStatusOptions
    },
    {
      type: 'Select',
      field: 'enableStatus',
      label: 'unitsMeasureGroups.enableStatus',
      option: {
        placeholder: 'unitsMeasureGroups.enableStatus'
      },
      optionData: getResourceStatusOptions
    }
  ];

  /**
   * table上方的表格操作配置
   */
  public operationOptions: Array<OperationOption> = [
    {
      type: 'primary',
      slot: 'start',
      label: 'button.add',
      operationType: 'add',
      icon: 'el-icon-circle-plus-outline',
      permissionCode: 'install:serveItems:save',
      handleClick: (): void => {
        this.add();
      }
    },
    {
      type: 'danger',
      slot: 'start',
      label: 'button.delete',
      operationType: 'delete',
      icon: 'el-icon-delete',
      permissionCode: 'install:serveItems:delete',
      plain: true,
      disabled: true,
      handleClick: (): void => {
        this.batchDelete();
      }
    },
    {
      type: 'primary',
      slot: 'start',
      label: 'unitsMeasureGroups.batchAudit',
      operationType: 'batchAudit',
      permissionCode: 'install:serveItems:approve',
      disabled: true,
      handleClick: (): void => {
        this.batchAudit();
      }
    },
    {
      type: 'danger',
      slot: 'start',
      label: 'unitsMeasureGroups.batchReject',
      operationType: 'batchReject',
      permissionCode: 'install:serveItems:cancelApprove',
      plain: true,
      disabled: true,
      handleClick: (): void => {
        this.batchReject();
      }
    },
    // {
    //   type: 'primary',
    //   slot: 'start',
    //   label: 'button.import',
    //   operationType: 'import',
    //   permissionCode: 'install:serveItems:importData',
    //   handleClick: (): void => {
    //     this.openImportFileDialog();
    //   }
    // },
    {
      type: 'primary',
      slot: 'end',
      label: 'button.using',
      operationType: 'using',
      icon: 'el-icon-open',
      permissionCode: 'install:serveItems:editEnableStatus',
      handleClick: (): void => {
        this.batchUpdateStatus(ResourceStatusEnum.using);
      },
      disabled: true
    },
    {
      type: 'danger',
      slot: 'end',
      plain: true,
      label: 'button.disabled',
      operationType: 'disabled',
      icon: 'el-icon-turn-off',
      permissionCode: 'install:serveItems:editEnableStatus',
      handleClick: (): void => {
        this.batchUpdateStatus(ResourceStatusEnum.disabled);
      },
      disabled: true
    }
  ];

  public dialogVisible: boolean = false;
  public dialogDetailsVisible: boolean = false;
  public serviceItemInfo: ServiceItemList | null = null;
  public importTitle = 'dialog.importExchangeRate';

  public editRow: CreateServiceItem | null = null;

  /**
   * table行的操作配置
   */
  public rowOperationOptions: RowOperation<ServiceItemList> = {
    fixed: 'right',
    width: '100px',
    operations: [
      {
        operationType: 'edit',
        type: 'text',
        label: 'button.edit',
        icon: 'el-icon-edit',
        permissionCode: 'install:serveItems:edit',
        dynamicHidden: (rowData: ServiceItemList): boolean => {
          return (
            rowData.enableStatus === ResourceStatusEnum.disabled ||
            rowData.approveStatus === PublicApproveStatusEnum.passed
          );
        },
        handleClick: this.edit
      }
    ]
  };

  public selectedRows: Array<ServiceItemList> = [];
  public paging: Paging = {
    currentPage: 1,
    showCount: 10
  };

  private queryForm: Partial<ServiceItemQuery> = {};

  private sortOptions: SortOptions<ServiceItemList> = this.tableOption.defaultSort!;

  public created(): void {
    this.initColumns(this.defaultColumnOptions, 'serviceItem');
    this.loadData();
  }

  public activated(): void {
    this.loadData();
  }

  public reloadData(): void {
    this.paging.currentPage = 1;
    this.clearSelection();
    this.loadData();
  }

  public handleSortChange(sortOptions: SortOptions<ServiceItemList>): void {
    this.sortOptions = sortOptions;
    this.reloadData();
  }

  public linkToDetails(serviceItem: ServiceItemList): void {
    // 打开详情 弹窗
    ServiceItemService.getById(serviceItem.id)
      .then(res => {
        this.serviceItemInfo = res;
        this.dialogDetailsVisible = true;
      })
      .catch(error => {
        messageError(error);
      });
  }

  public handleSelectionChange(selectedData: Array<ServiceItemList>): void {
    this.selectedRows = selectedData;
  }

  public getStatusI18Key(status: ResourceStatusEnum): string {
    let res;
    switch (status) {
      case ResourceStatusEnum.using:
        res = 'common.startUsing';
        break;
      case ResourceStatusEnum.disabled:
        res = 'common.disabled';
        break;
      default:
        res = 'common.unKnownStatus';
        break;
    }
    return res;
  }

  public getStatusClass(status: ResourceStatusEnum): string {
    return status === ResourceStatusEnum.using ? 'start-dot' : 'disabled-dot';
  }
  public getApproveStatusClass(status: PublicApproveStatusEnum): string {
    return status === PublicApproveStatusEnum.passed ? 'success-dot' : 'disabled-dot';
  }

  public getApproveStatusI18Key(status: PublicApproveStatusEnum): string {
    return 'publicApproveStatus.' + PublicApproveStatusEnum[status];
  }
  @debounce()
  private loadData(): void {
    this.tableOption.loading = true;
    ServiceItemService.getList(this.queryForm, this.paging)
      .then(res => {
        this.tableOption.data = res.data;
        this.totalData = res.total || 0;
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.tableOption.loading = false;
      });
  }

  @Watch('selectedRows')
  private handleSelectedChanged(value: Array<ServiceItemList>): void {
    this.operationOptions.forEach(x => {
      if (!['import', 'add'].includes(x.operationType)) {
        x.disabled = value.length === 0;
      }
    });
  }

  private clearSelection(): void {
    (this.$refs.serviceItemTable as OsTable).clearSelection();
    this.selectedRows = [];
  }

  private add(): void {
    // 新增数据
    this.dialogVisible = true;
  }
  private edit(rowData: ServiceItemList): void {
    // 修改数据
    this.editRow = rowData;
    this.dialogVisible = true;
  }

  private dialogClosed(): void {
    this.editRow = null;
  }
  private deleteConfirm(): Promise<MessageBoxData> {
    return MessageBox.confirm(translation('tip.confirmDelete'), translation('tip.tipInfo'), {
      confirmButtonText: translation('button.ok'),
      cancelButtonText: translation('button.cancel'),
      type: 'warning'
    });
  }
  /**
   * 批量删除
   */
  private async batchDelete(): Promise<void> {
    const idList: Array<number> = this.selectedRows
      .filter(
        item =>
          item.approveStatus === PublicApproveStatusEnum.waitApprove &&
          Number(item.enableStatus) === ResourceStatusEnum.using
      )
      .map(x => x.id!);

    if (idList.length === 0) {
      Message.warning(translation('serviceItem.selectDeleteTip1'));
      return;
    }
    this.deleteConfirm()
      .then(async () => {
        try {
          await ServiceItemService.batchCurrencyDelete(idList);
          this.reloadData();
          Message.success(translation('operationRes.deleteSuccess'));
        } catch (error) {
          messageError(error);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelDelete'));
      });
  }
  /**
   * 审核
   */
  private async batchAudit(): Promise<void> {
    try {
      const idList: Array<number> = this.selectedRows
        .filter(
          item =>
            item.approveStatus === PublicApproveStatusEnum.waitApprove &&
            Number(item.enableStatus) === ResourceStatusEnum.using
        )
        .map(x => x.id!);
      if (idList.length === 0) {
        Message.error(translation('serviceItem.selectWaitApproveData'));
        return;
      }
      await ServiceItemService.batchCurrencyApprove(idList);
      this.reloadData();
      Message.success(translation('operationRes.operationSuccess'));
    } catch (error) {
      messageError(error);
    }
  }

  /**
   * 反审核
   */
  private async batchReject(): Promise<void> {
    try {
      const idList: Array<number> = this.selectedRows
        .filter(
          item =>
            item.approveStatus === PublicApproveStatusEnum.passed &&
            Number(item.enableStatus) === ResourceStatusEnum.using
        )
        .map(x => x.id!);
      if (idList.length === 0) {
        Message.error(translation('serviceItem.selectPassedData'));
        return;
      }
      await ServiceItemService.batchCurrencyCancelApprove(idList);
      this.reloadData();
      Message.success(translation('operationRes.operationSuccess'));
    } catch (error) {
      messageError(error);
    }
  }

  // private openImportFileDialog(): void {
  //   this.importDialogVisible = true;
  // }
  // private downloadTemplate(): void {
  //   (this.$refs.importDialog as ImportFile).setDownloadLoading(true);
  //   ServiceItemService
  //     .downloadTemplate()
  //     .then((blob: any) => {
  //       downloadFileByBlob('汇率导入模板.xlsx', blob);
  //     })
  //     .catch(error => {
  //       messageError(error);
  //     })
  //     .finally(() => {
  //       (this.$refs.importDialog as ImportFile).setDownloadLoading(false);
  //     });
  // }
  // private importSuccess(path: string): void {
  //   ServiceItemService
  //     .importCustomer(path)
  //     .then(() => {
  //       this.reloadData();
  //       Message.success(translation('dialog.importSuccess'));
  //       this.importDialogVisible = false;
  //     })
  //     .catch((error: ApiResponse<ImportRes>) => {
  //       handleImportError(error);
  //       (this.$refs.importDialog as ImportFile).setLoading(false);
  //     });
  // }

  /**
   * 批量修改状态
   * @param status 状态 启用还是禁用
   */
  private batchUpdateStatus(status: ResourceStatusEnum): void {
    const idList = this.selectedRows.filter(item => item.enableStatus !== status).map(x => x.id!);
    if (idList.length === 0) {
      const error =
        status === ResourceStatusEnum.using
          ? translation('common.selectDisabledData')
          : translation('common.selectUsingData');
      Message.error(error);
      return;
    }
    ServiceItemService.batchCurrentUpdateStatus(idList, status)
      .then(() => {
        this.reloadData();
        Message.success(
          status === ResourceStatusEnum.using
            ? translation('common.usingSuccess')
            : translation('common.disabledSuccess')
        );
      })
      .catch(error => {
        messageError(error);
      });
  }
}
