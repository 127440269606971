import { InstallEmployService } from '@/api';
import { OsAddress } from '@/components';
import { DialogMixin } from '@/mixins/dialog';
import { ResourceStatusEnum } from '@/resource/enum';
import { CreateInstallEmployee, InstallEmployeeList, UpdateInstallEmployee } from '@/resource/model';
import { convertProvinces, limitPreviousForTimePicker, messageError, translation } from '@/utils';
import { mobileReg } from '@/utils/validate';
import { Message } from 'element-ui';
import { ElForm } from 'element-ui/types/form';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { SelectServiceItem } from '@/views/dialogs';

@Component({
  components: { SelectServiceItem }
})
export default class AddInstallEmployee extends Mixins(DialogMixin) {
  @Prop({
    required: false,
    type: Object,
    default: () => {
      return null;
    }
  })
  public employee!: InstallEmployeeList | null;

  public positionDialogVisible = false;

  public employeeForm: CreateInstallEmployee | UpdateInstallEmployee = {
    realName: '',
    mobile: '',
    gender: 1,
    birthday: undefined,
    serveItems: '',
    serviceItem: [],
    status: ResourceStatusEnum.using,
    address: [],
    remark: ''
  };

  public resourceFormRules: { [P in keyof Partial<CreateInstallEmployee>]: Array<object> } = {
    realName: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('personnel.inputRealName')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ],
    mobile: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('personnel.inputMobile')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      },
      {
        validator: (rule: any, value: string, callback: Function): void => {
          if (!mobileReg(value)) {
            callback(new Error(this.$t('validate.mobileIllegal') as string));
            return;
          }
          callback();
        },
        trigger: 'blur'
      },
      {
        validator: (rule: any, value: string, callback: Function): void => {
          InstallEmployService.checkPhoneNumber(value, this.employee?.id)
            .then((isRepeat: boolean) => {
              if (isRepeat) {
                callback(translation('requestError.mobileRepeat'));
                return;
              }
              callback();
            })
            .catch(error => {
              callback(error);
            });
        },
        trigger: 'blur'
      }
    ],
    serviceItem: [
      {
        required: true,
        validator: (rule: any, value: Array<any>, callback: Function): void => {
          console.log(!!value, value);
          if (value.length === 0) {
            callback(new Error(translation('common.select')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ],
    address: [
      {
        required: true,
        validator: (rule: any, value: Array<any>, callback: Function): void => {
          console.log(!!value, value);
          if (value.length === 0) {
            callback(new Error(translation('common.select')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ]
  };

  public limitDateRangeOption = {
    disabledDate: limitPreviousForTimePicker
  };

  public selectserviceItemDialog: boolean = false;
  private operationType: 'add' | 'edit' = 'add';

  public deleteServeitem(index: number): void {
    this.employeeForm.serviceItem.splice(index, 1);
  }

  public openSelectserviceItemDialog(): void {
    this.selectserviceItemDialog = true;
  }

  public handleSelectServiceItem(serveItems: Array<any>): void {
    this.employeeForm.serviceItem = serveItems.map(item => {
      return {
        id: item.id,
        name: item.name
      };
    });
  }

  public closeServeItemDialog(): void {
    (this.$refs.selectServeItemRef as any).blur();
  }

  public created(): void {
    if (!this.employee) {
      this.operationType = 'add';
      this.title = 'installEmployee.add';
      return;
    }
    this.operationType = 'edit';
    this.title = 'installEmployee.edit';
    const provinces: Array<string> = [];
    if (this.employee.province) {
      provinces.push(this.employee.province);
    }
    if (this.employee.city) {
      provinces.push(this.employee.city);
    }
    if (this.employee.district) {
      provinces.push(this.employee.district);
    }
    this.employeeForm.address = provinces;
    if (this.employee.serveItems) {
      this.employeeForm.serviceItem = JSON.parse(this.employee.serveItems);
    }
    (this.$refs.osAddress as OsAddress)?.reload();
    this.$nextTick(() => {
      Object.assign(this.employeeForm, this.employee);
    });
  }

  public destroyed(): void {
    this.$emit('dialog-closed');
  }

  public async onSubmit(): Promise<void> {
    try {
      await (this.$refs.employeeForm as ElForm).validate();
      this.setLoading(true);
      this.employeeForm.serveItems = JSON.stringify(this.employeeForm.serviceItem);

      Object.assign(this.employeeForm, convertProvinces(this.employeeForm.address));
      if (this.operationType === 'add') {
        await InstallEmployService.post(this.employeeForm);
        Message.success(translation('operationRes.addSuccess'));
        this.$emit('add-success');
        this.closeDialog();
        return;
      }
      await InstallEmployService.put(this.employeeForm as UpdateInstallEmployee);
      Message.success(translation('operationRes.editSuccess'));
      this.$emit('edit-success', this.employeeForm);
      this.closeDialog();
    } catch (error) {
      if (error) {
        messageError(error);
      }
    } finally {
      this.setLoading(false);
    }
  }

  public handlePaste(): void {
    navigator.clipboard
      .readText()
      .then(res => {
        this.employeeForm.mobile = res.replace(/\s*/g, '');
      })
      .catch(err => {
        console.log('失败', err);
      });
  }
}
