import { ServiceItemService } from '@/api';

import { DialogMixin } from '@/mixins/dialog';
import { CreateServiceItem } from '@/resource/model';
import { NormalSelectOptions } from '@/resource/model/common';
import { limitFutureForTimePicker, messageError, translation } from '@/utils';
import { Form, Message } from 'element-ui';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'AddServiceItem',
  components: {}
})
export default class AddServiceItem extends mixins(DialogMixin) {
  @Prop({
    required: false,
    type: Object,
    default: () => {
      return null;
    }
  })
  public position!: CreateServiceItem | null;
  public currencyDialogVisible: boolean = false;
  public valueType: string = '';

  public form: CreateServiceItem = {
    id: undefined,
    name: '',
    code: '',
    type: 1
  };

  public resourceFormRules = {
    name: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          console.log('校验', !value.trim());
          if (!value.trim()) {
            callback(new Error(translation('serviceItem.inputName')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      },
      {
        validator: (rule: any, value: string, callback: Function): void => {
          ServiceItemService.checkName(value, this.form?.id)
            .then((isRepeat: boolean) => {
              if (isRepeat) {
                callback(translation('common.nameRepeat'));
                return;
              }
              callback();
            })
            .catch(error => {
              callback(error);
            });
        },
        trigger: 'blur'
      }
    ],
    code: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value.trim()) {
            callback(new Error(translation('serviceItem.inputCode')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      },
      {
        validator: (rule: any, value: string, callback: Function): void => {
          ServiceItemService.checkCode(value, this.form?.id)
            .then((isRepeat: boolean) => {
              if (isRepeat) {
                callback(translation('common.codeRepeat'));
                return;
              }
              callback();
            })
            .catch(error => {
              callback(error);
            });
        },
        trigger: 'blur'
      }
    ],
    type: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('serviceItem.selectType')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ]
  };

  public businessTypes: NormalSelectOptions = [];

  public limitDateRangeOption = {
    disabledDate: limitFutureForTimePicker
  };

  private operationType: 'add' | 'edit' = 'add';

  public dialogOpen(): void {
    this.getBusinessType();
    if (this.position) {
      this.operationType = 'edit';
      this.title = 'serviceItem.title';
      this.$nextTick(() => {
        Object.assign(this.form, this.position);
      });

      return;
    }
    this.operationType = 'add';
    this.title = 'serviceItem.title';
  }

  public dialogClosed(): void {
    this.form.id = undefined;
    this.$emit('dialog-closed');
    (this.$refs.serviceItemForm as Form).resetFields();
  }

  public openSelectCurrencyDialog(value: string): void {
    this.currencyDialogVisible = true;
    this.valueType = value;
  }
  public onSubmit(): void {
    (this.$refs.serviceItemForm as Form).validate(async (valid: boolean) => {
      console.log(valid);
      if (!valid) {
        return;
      }
      this.setLoading(true);
      try {
        if (this.operationType === 'add') {
          await ServiceItemService.post({ ...this.form } as any);
          Message.success(translation('operationRes.operationSuccess'));
          this.$emit('operate-success');
          this.closeDialog();
          return;
        }
        await ServiceItemService.put({ ...this.form } as any);
        Message.success(translation('operationRes.operationSuccess'));
        this.$emit('operate-success', this.form);
        this.closeDialog();
      } catch (error) {
        messageError(error);
      } finally {
        this.setLoading(false);
      }
    });
  }

  public async getBusinessType(): Promise<void> {
    try {
      this.businessTypes = await ServiceItemService.getBusinessType();
    } catch (error) {
      messageError(error);
    }
  }
}
