import { InstallEmployService } from '@/api';
import { ApiResponse } from '@/api/axios';
import { OperationOption } from '@/components/os-table-operation/os-table-operation';
import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';
import { OsTableColumn, OsTableOption, RowOperation } from '@/components/os-table/os-table';
import { PagingMixin } from '@/mixins/paging';
import { ImportTemplateEnum, ResourceStatusEnum } from '@/resource/enum';
import { ImportRes, InstallEmployeeList, InstallEmployeeListQuery, UpdateInstallEmployee } from '@/resource/model';
import {
  dateFormat,
  downloadFileByBlob,
  getResourceStatusOptions,
  getStatusClass,
  getStatusI18Key,
  handleImportError,
  messageError,
  translation
} from '@/utils';
import { ImportFile } from '@/views/dialogs';
import { Message } from 'element-ui';
import { ElTable } from 'element-ui/types/table';
import { Component, Mixins, Watch } from 'vue-property-decorator';
import AddInstallEmployee from './add-install-employee/add-install-employee.vue';

@Component({
  components: { ImportFile, AddInstallEmployee }
})
export default class InstallEmployee extends Mixins(PagingMixin) {
  public tableOption: OsTableOption<InstallEmployeeList> = {
    loading: false,
    data: [],
    fit: true
  };

  /**
   * 表格列配置
   */
  public columnOption: Array<OsTableColumn<InstallEmployeeList>> = [
    {
      type: 'selection',
      prop: 'id',
      label: '',
      reserveSelection: true
    },
    {
      prop: 'realName',
      label: 'installEmployee.name',
      minWidth: '120px',
      fixed: 'left'
    },
    {
      prop: 'mobile',
      label: 'installEmployee.mobile',
      minWidth: '120px'
    },
    {
      prop: 'nickname',
      label: 'installEmployee.nickname',
      minWidth: '120px'
    },
    {
      prop: 'gender',
      label: 'personnel.gender',
      minWidth: '80px',
      formatter: (row: object): string => {
        return (row as InstallEmployeeList).gender === 1 ? translation('common.man') : translation('common.woman');
      }
    },
    {
      prop: 'birthday',
      label: 'installEmployee.birthday',
      minWidth: '150px',
      formatter: (row: object): string => {
        return dateFormat((row as InstallEmployeeList).birthday!, 'YYYY-MM-DD');
      }
    },
    {
      prop: 'status',
      label: 'common.status',
      minWidth: '100px'
    },
    {
      prop: 'bindWechat',
      label: 'installEmployee.whetherBindWechat',
      minWidth: '80px',
      formatter: (row: object): string => {
        return (row as InstallEmployeeList).bindWechat
          ? translation('installEmployee.bound')
          : translation('installEmployee.unbound');
      }
    },
    {
      prop: 'province',
      label: 'installEmployee.area',
      minWidth: '240px',
      showOverflowTooltip: true,
      formatter: (row: object): string => {
        if (!(row as InstallEmployeeList).province) {
          return '--';
        }
        return `${(row as InstallEmployeeList).province}
        ${(row as InstallEmployeeList).city ? (row as InstallEmployeeList).city : ''}
        ${(row as InstallEmployeeList).district ? (row as InstallEmployeeList).district : ''}`;
      }
    },
    {
      prop: 'remark',
      label: 'common.remark',
      showOverflowTooltip: true,
      minWidth: '150px'
    },
    {
      prop: 'createTime',
      label: 'common.createTime',
      showOverflowTooltip: true,
      minWidth: '180px',
      formatter: (row: object): string => {
        return dateFormat((row as InstallEmployeeList).createTime);
      }
    }
  ];

  /**
   * table上方的条件查询配置
   */
  public queryItemsOption: Array<OsQueryItemOption<InstallEmployeeListQuery>> = [
    {
      type: 'Input',
      field: 'realName',
      label: 'installEmployee.name',
      option: {
        placeholder: 'personnel.inputRealName'
      }
    },
    {
      type: 'Input',
      field: 'mobile',
      label: 'installEmployee.mobile',
      option: {
        placeholder: 'personnel.inputMobile'
      }
    },
    {
      type: 'Input',
      field: 'nickname',
      label: 'installEmployee.nickname',
      option: {
        placeholder: 'installEmployee.inputNickname'
      }
    },
    {
      type: 'Select',
      field: 'status',
      label: 'common.status',
      option: {
        placeholder: 'common.selectStatus'
      },
      optionData: getResourceStatusOptions
    },
    {
      type: 'Input',
      field: 'city',
      label: 'installEmployee.area',
      option: {
        placeholder: 'installEmployee.inputArea'
      }
    }
  ];

  /**
   * table上方的表格操作配置
   */
  public operationOptions: Array<OperationOption> = [
    {
      type: 'primary',
      slot: 'start',
      label: 'button.add',
      operationType: 'add',
      icon: 'el-icon-circle-plus-outline',
      permissionCode: 'install:employee:save',
      handleClick: this.openAddDialog
    },
    {
      type: 'primary',
      slot: 'start',
      label: 'button.import',
      operationType: 'import',
      icon: 'el-icon-upload2',
      permissionCode: 'install:employee:importData',
      handleClick: this.openImportDialog
    },
    {
      type: 'primary',
      slot: 'end',
      label: 'button.using',
      operationType: 'using',
      icon: 'el-icon-open',
      permissionCode: 'install:employee:editStatus',
      handleClick: (): void => {
        this.batchUpdateStatus(ResourceStatusEnum.using);
      },
      disabled: true
    },
    {
      type: 'danger',
      slot: 'end',
      plain: true,
      label: 'button.disabled',
      operationType: 'disabled',
      icon: 'el-icon-turn-off',
      permissionCode: 'install:employee:editStatus',
      handleClick: (): void => {
        this.batchUpdateStatus(ResourceStatusEnum.disabled);
      },
      disabled: true
    }
  ];

  /**
   * table行的操作配置
   */
  public rowOperationOptions: RowOperation<InstallEmployeeList> = {
    fixed: 'right',
    width: '100px',
    operations: [
      {
        operationType: 'edit',
        type: 'text',
        label: 'button.edit',
        icon: 'el-icon-edit',
        permissionCode: 'install:employee:edit',
        handleClick: this.openEditDialog
      }
    ]
  };

  public selectedRows: Array<InstallEmployeeList> = [];

  public pendingEditRow: InstallEmployeeList | null = null;

  public addVisible = false;

  public importVisible = false;

  public importTemplate: ImportTemplateEnum = ImportTemplateEnum.installEmployee;

  private queryForm: Partial<InstallEmployeeListQuery> = {};

  public created(): void {
    this.loadData();
  }

  public handleSelectionChange(selectedRows: Array<InstallEmployeeList>): void {
    this.selectedRows = selectedRows;
  }

  public queryClick(): void {
    this.paging.currentPage = 1;
    this.reloadData();
  }

  public loadData(): void {
    this.tableOption.loading = true;
    InstallEmployService.getList(this.queryForm, this.paging)
      .then(res => {
        this.tableOption.data = res.data;
        this.totalData = res.total;
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.tableOption.loading = false;
      });
  }

  public getStatusI18Key(status: ResourceStatusEnum): string {
    return getStatusI18Key(status);
  }

  public getStatusClass(status: ResourceStatusEnum): string {
    return getStatusClass(status);
  }

  public importInstallEmployee(path: string): void {
    InstallEmployService.importData(path)
      .then(() => {
        this.reloadData();
        Message.success(translation('dialog.importSuccess'));
        this.importVisible = false;
      })
      .catch((error: ApiResponse<ImportRes>) => {
        handleImportError(error);
        (this.$refs.importDialog as ImportFile).setLoading(false);
      });
  }

  public handleEditSuccess(data: UpdateInstallEmployee): void {
    const findItem = this.tableOption.data.find(x => x.id === data.id);
    Object.assign(findItem, data);
  }

  public resetEditRow(): void {
    this.pendingEditRow = null;
  }

  private reloadData(): void {
    (this.$refs.employeeTable as ElTable).clearSelection();
    this.selectedRows = [];
    this.loadData();
  }

  private batchUpdateStatus(status: ResourceStatusEnum): void {
    const idList = this.selectedRows.map(x => x.id);
    InstallEmployService.batchUpdateEmployeeStatus(idList, status)
      .then(() => {
        this.reloadData();
        Message.success(
          status === ResourceStatusEnum.using
            ? translation('common.usingSuccess')
            : translation('common.disabledSuccess')
        );
      })
      .catch(error => {
        messageError(error);
      });
  }

  private openAddDialog(): void {
    this.addVisible = true;
  }

  private openEditDialog(item: InstallEmployeeList): void {
    this.pendingEditRow = item;
    this.addVisible = true;
  }

  private openImportDialog(): void {
    this.importVisible = true;
  }

  /**
   * 下载上传模板
   */
  private downloadTemplate(): void {
    (this.$refs.importDialog as ImportFile).setDownloadLoading(true);
    InstallEmployService.downloadTemplate()
      .then((blob: any) => {
        downloadFileByBlob('工人导入模板.xlsx', blob);
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        (this.$refs.importDialog as ImportFile).setDownloadLoading(false);
      });
  }

  @Watch('selectedRows')
  private handleSelectedChanged(value: Array<InstallEmployeeList>): void {
    const dynamicBtnType = ['using', 'disabled'];
    this.operationOptions.forEach(x => {
      if (dynamicBtnType.includes(x.operationType)) {
        x.disabled = value.length === 0;
      }
    });
  }
}
